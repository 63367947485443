import axios from 'axios';
import authService from './auth.service';
import { BASE_URL, ASANA_DOWNLOAD_SERVICE_URL, KEYWORDS_SERVICE_URL } from './config.service';


const authorizedRequest = () => {
	const axiosInstance = axios.create({
		headers: {
			token: authService.getAuthToken(),
		},
	});

	axiosInstance.interceptors.response.use(
		response => {
			return response.data
		},
		error => {
			if (error.response && error.response.status === 401) {
				authService.logOut();
			}

			const definedServerError = error.response.data.error;

			if (definedServerError && typeof definedServerError === 'string') {
				return { error: definedServerError };
			}

			return { error: error.message };
		}
	);

	return axiosInstance;
};


const createCampaignInfoRequest = async () => {
	return authorizedRequest().get(BASE_URL + '/api/campaigns/form-meta-data');
};


const createCampaignDataResultRequest = async data => {
	return await authorizedRequest().post(BASE_URL + '/api/campaigns', data);
};


const createCampaignOverviewRequest = async () => {
	return authorizedRequest().get(BASE_URL + '/api/campaigns');
};


const createPixelsOverviewRequest = async () => {
	return authorizedRequest().get(BASE_URL + '/api/pixels');
};


const createAddAccountsRequest = async data => {
	return authorizedRequest().post(BASE_URL + '/api/ad-accounts', data);
};


const createAccountsOverviewRequest = async () => {
	return authorizedRequest().get(BASE_URL + '/api/ad-accounts');
};


const createPixelRequest = async data => {
	return authorizedRequest().post(BASE_URL + '/api/pixels', data);
};


const createPixelOverviewRequest = async () => {
	return authorizedRequest().get(BASE_URL + '/api/pixels');
};


const getKeywordsDataForOffer = async ({offer, ...query}) => {
	let response;

	const searchParams = new URLSearchParams();

	Object.keys(query).forEach(key => {
		if (query[key]) {
			searchParams.set(key, query[key]);
		}
	});

	const data = await authorizedRequest().get(KEYWORDS_SERVICE_URL + `/api/keywords/1/keywords/${offer}?${searchParams.toString()}`);

	if (data.error) {
		return data;
	}

	const keysMap = ['offer', 'avg', 'clicks', 'median', 'percentile25', 'percentile75'];
	const buildRecord = recordArray => {
		const result = {};

		recordArray.forEach((value, index) => {
			result[keysMap[index]] = value
		});

		return result;
	};


	return {
		table: data.table.map(record => buildRecord(record))
	}
};


const getKeywordsPageMetadata = async affilateId => {
	return authorizedRequest().get(KEYWORDS_SERVICE_URL + `/api/keywords/1/metadata?affilateId=${affilateId}`);
};


const getKeywordsSummary = async (aggregation, summaryType, periodId, query) => {
	let response;

	const limit = query.limit || 10;
	const day = query.day || null;
	const minNumberOfClicks = query.minNumberOfClicks || 50;
	const minAVGRevenue = query.minAVGRevenue || 0;

	if (query.country && query.country.toLowerCase() === 'all') {
		query.country = null;
	}

	const country = query.country || null;

	const searchParams = new URLSearchParams();
	Object.keys({limit, country, day, minNumberOfClicks, minAVGRevenue}).forEach(key => {
		if (query[key]) {
			searchParams.set(key, query[key]);
		}
	});

	return authorizedRequest().get(KEYWORDS_SERVICE_URL + `/api/keywords/1/summary/${aggregation}/${summaryType}/${periodId}?${searchParams.toString()}`);
};


const getSeasonalityByPeriodData = async (offerId, analysisType, query = {}) => {
	const searchParams = new URLSearchParams();

	Object.keys(query).forEach(key => {
		if (query[key]) {
			searchParams.set(key, query[key]);
		}
	});

	return authorizedRequest().get(KEYWORDS_SERVICE_URL + `/api/keywords/1/keywords/${offerId}/analyse/${analysisType}?${searchParams.toString()}`);
};


const deletePixelRequest = async pixelId => {
	return authorizedRequest().delete( '/api/pixels/' + pixelId);
};


const getAnalysedOffersList = async query => {
	const searchParams = new URLSearchParams();

	Object.keys(query).forEach(key => {
		if (query[key]) {
			searchParams.set(key, query[key]);
		}
	});

	return authorizedRequest().get(KEYWORDS_SERVICE_URL + `/api/keywords/1/offers?${searchParams.toString()}`);
};


const getAnalysedKeywordsList = async query => {
	const searchParams = new URLSearchParams();

	Object.keys(query).forEach(key => {
		if (query[key]) {
			searchParams.set(key, query[key]);
		}
	});

	return authorizedRequest().get(KEYWORDS_SERVICE_URL + `/api/keywords/1/keywords?offerId=all&${searchParams.toString()}`);
};


const getAdAccountsMetadata = async () => {
	return authorizedRequest().get(BASE_URL + `/api/meta-ad-accounts/metadata`);
};


const getCreativesCTRById = async query => {
	const searchParams = new URLSearchParams();

	for (const [key, value] of Object.entries(query)) {
		if (value) {
			if (Array.isArray(value)) {
				searchParams.set(key, value.join(','));
			} else {
				searchParams.set(key, value);
			}
		}
	}	

	return authorizedRequest().get(BASE_URL + `/api/meta-ad-accounts/creatives-ctr?${searchParams.toString()}`);
};


const getAttachmentsFormMetaData = () => {
	return authorizedRequest().get(BASE_URL + `/api/attachments/metadata`);
};


const importAttachment = ({asanaTaskId, integrationType, businessCenterId, adAccountId}) => {
	return authorizedRequest().post(ASANA_DOWNLOAD_SERVICE_URL + `/api/asana_download_attachments/1/send-attachments/${asanaTaskId}/${integrationType}/${businessCenterId}/${adAccountId}`);
};


const getImportAttachmentStatus = (id) => {
	return authorizedRequest().get(ASANA_DOWNLOAD_SERVICE_URL + `/api/asana_download_attachments/1/import_status/${id}`);
}


const getExchangeRate = (currency) => {
	return authorizedRequest().get(BASE_URL + `/api/exchange-rate/latest/${currency}`);
}


const linkToDownloadCreativesZipFromAsana = asanaTaskId => {
	return ASANA_DOWNLOAD_SERVICE_URL + `/api/asana_download_attachments/1/download-attachments/${asanaTaskId}`;
};


const getCampaignKeywords = async campaignId => {
	return authorizedRequest().get(BASE_URL + `/api/campaigns/keywords/${campaignId}`);
};


const updateCampaignKeywords = async data => {
	return authorizedRequest().post(BASE_URL + '/api/campaigns/update-keywords', data);
};


const apiCallsService = {
	createCampaignInfoRequest,
	createCampaignDataResultRequest,
	createCampaignOverviewRequest,
	createPixelsOverviewRequest,
	createAddAccountsRequest,
	createAccountsOverviewRequest,
	createPixelRequest,
	createPixelOverviewRequest,
	getKeywordsDataForOffer,
	getKeywordsPageMetadata,
	deletePixelRequest,
	getSeasonalityByPeriodData,
	getKeywordsSummary,
	getAnalysedOffersList,
	getAnalysedKeywordsList,
	getAttachmentsFormMetaData,
	importAttachment,
	getImportAttachmentStatus,
	getExchangeRate,
	getAdAccountsMetadata,
	getCampaignKeywords,
	updateCampaignKeywords,
	getCreativesCTRById,
	linkToDownloadCreativesZipFromAsana
};


export default apiCallsService;
